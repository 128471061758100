import {getHttpClient} from "./API";
import {ApiUrls} from "../enums/ApiUrls";
import {getGlobalState} from "../state/GlobalState";
import {getCurrentCountry} from "./CountryService";

export async function getOrderHistoryByRegionAndOfferingId(
  regionCode: string,
  offeringId: string
): Promise<[]> {
  const result = await getHttpClient().get(
    `${ApiUrls.orderHistoryBy}/${offeringId}/ByRegion/${regionCode}`
  );
  return result.data;
}

export async function getOrderHistoryBySalesCodeAndOfferingId(
  salesCode: string,
  offeringId: string
): Promise<[]> {
  getCurrentCountry(salesCode);
  const result = await getHttpClient().get(
    `${ApiUrls.orderHistoryBy}/${offeringId}/BySalesCode/${salesCode}?countryCode=${getGlobalState("country")}&languageCode=${getGlobalState("language")}`
  );
  return result.data;
}

export async function getFullReportData() {
  return await getHttpClient().get(ApiUrls.exportFullReport);
}

export async function getRebillingReportData() {
  return await getHttpClient().get(ApiUrls.exportRebillingReport);
}
