import React from "react";
import ReactTooltip from "react-tooltip";
import {getIcon, IconName} from "../../service/iconFinderService";
import styles from "../../styles/ModelEMenu.module.scss";
import {Dealer} from "../../service/DealerService";

export function ModelEMenu(props: {dealer?: Dealer}) {
  function getHref() {
    return props.dealer?.isECommerceEnrolled
      ? `https://www.ford.com/guest/menu.${props.dealer?.paCode.paCode}.html?dealerId=${props.dealer?.paCode.paCode}`
      : `https://www.ford.com/guest/menu`;
  }

  return (
    <div className={styles.menuContainer}>
      <img
        className={styles.menuImage}
        src={getIcon(IconName.Info)}
        alt={"Model e menu link"}
      />
      <div>For Customer Orders, visit</div>
      <div className={"model-e-menu-tooltip"}>
        <ReactTooltip
          role={"tooltip"}
          place={"bottom"}
          effect={"solid"}
          id={"model-e-menu-tooltip"}
          disable={props.dealer !== undefined}
        />
        <span
          data-tip={"Enter a sales code to view respective menu"}
          data-for={"model-e-menu-tooltip"}
          data-testid={"model-e-menu-tooltip"}>
          <a
            className={`${styles.menuButton} ${!props.dealer?.salesCode && styles.disabled}`}
            href={getHref()}
            target="_blank"
            rel="noopener noreferrer">
            Model e Menu
          </a>
        </span>
      </div>
    </div>
  );
}
