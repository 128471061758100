import React, {LegacyRef, useEffect, useRef, useState} from "react";
import {Table} from "antd";
import {TableColumn} from "../../models/TableColumn";
import {CSVLink} from "react-csv";
import "../../styles/Table.scss";
import {
  retrieveAllAvailableSalesCodesForAuthenticatedUser,
  SalesCodeType,
} from "../../service/SalesCodeService";
import {
  getCompletedOfferingsForDealer,
  Offering,
} from "../../service/OfferingService";
import Select from "react-select";
import {getOrderHistoryBySalesCodeAndOfferingId} from "../../service/ReportService";
import {useGlobalState} from "../../state/GlobalState";
import {getCountryLocale} from "../modelE/Utils";
import {TextTranslation, useTranslation} from "../../utils/Translations";

export function OrderHistoryBySalesCode() {
  const [salesCodes, setSalesCodes] = useState<SalesCodeType[]>([]);
  const [offerings, setOfferings] = useState([]);
  const [selectedSalesCode, setSelectedSalesCode] = useState({
    code: "",
    name: "",
  });
  const [selectedOffering, setSelectedOffering] = useState({
    id: "",
    dateRange: "",
  });
  const [downloadButtonEnabled, setDownloadButtonEnabled] = useState(false);
  const [currentLanguage] = useGlobalState("language");
  const [offeringDropDownButtonEnabled, setOfferingDropDownButtonEnabled] =
    useState(false);
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const [orderHistoryFileName, setOrderHistoryFileName] = useState("");
  const csvDownloadLink: LegacyRef<any> = useRef();
  const translations = useTranslation([
    "orderHistorySalesCodeLabel",
    "dealershipNameLabel",
    "orderHistoryOfferingPeriodLabel",
    "vehicleLineLabel",
    "vinLabel",
    "bodyLabel",
    "codeLabel",
    "engineLabel",
    "paintLabel",
    "trimLabel",
    "statusLabel",
    "userLabel",
    "optionsLabel",
    "packagesLabel",
    "orderHistoryOf",
    "orderHistoryFor",
    "salesCodePlaceholderText",
    "offeringPlaceholderText",
  ]);
  const headers: {label: string; key: string}[] = [
    {
      label: translations["orderHistorySalesCodeLabel"],
      key: "salesCode",
    },
    {
      label: translations["dealershipNameLabel"],
      key: "dealershipName",
    },
    {
      label: translations["orderHistoryOfferingPeriodLabel"],
      key: "offeringPeriod",
    },
    {
      label: translations["vehicleLineLabel"],
      key: "vehicleLine",
    },
    {label: translations["vinLabel"], key: "vin"},
    {label: translations["bodyLabel"], key: "body"},
    {label: translations["codeLabel"], key: "code"},
    {
      label: translations["engineLabel"],
      key: "engine",
    },
    {label: translations["paintLabel"], key: "paint"},
    {label: translations["trimLabel"], key: "trim"},
    {
      label: translations["statusLabel"],
      key: "status",
    },
    {label: translations["userLabel"], key: "user"},
    {
      label: translations["optionsLabel"],
      key: "options",
    },
    {
      label: translations["packagesLabel"],
      key: "packages",
    },
  ];

  function getFormattedDate(offering: Offering) {
    return (
      new Date(offering.startTime).toLocaleString(
        [getCountryLocale(currentLanguage)],
        {
          month: "short",
          day: "numeric",
        }
      ) +
      " - " +
      new Date(offering.endTime).toLocaleString(
        [getCountryLocale(currentLanguage)],
        {
          month: "short",
          day: "numeric",
          year: "numeric",
        }
      )
    );
  }

  useEffect(() => {
    retrieveAllAvailableSalesCodesForAuthenticatedUser()
      .then((salesCodes) => {
        setSalesCodes(salesCodes);
      })
      .catch((error) => console.log(error.message));
  }, []);

  useEffect(() => {
    if (selectedSalesCode.code !== "") {
      getCompletedOfferingsForDealer(selectedSalesCode.code).then(
        (offeringData) => {
          setOfferings(
            offeringData.map((offering: Offering) => {
              return {label: getFormattedDate(offering), value: offering._id};
            })
          );
        }
      );
      setOfferingDropDownButtonEnabled(true);
    }
    // eslint-disable-next-line
  }, [selectedSalesCode]);

  const downloadButtonHandler = () => {
    getOrderHistoryBySalesCodeAndOfferingId(
      selectedSalesCode.code,
      selectedOffering.id
    ).then((response) => {
      setOrderHistoryData(response);
      setOrderHistoryFileName(
        translations["orderHistoryOf"] +
          selectedSalesCode.name +
          translations["orderHistoryFor"] +
          selectedOffering.dateRange +
          ".csv"
      );
      if (csvDownloadLink.current) csvDownloadLink.current.link.click();
    });
  };

  useEffect(() => {
    setDownloadButtonEnabled(
      selectedSalesCode.code !== "" && selectedOffering.id !== ""
    );
  }, [selectedSalesCode, selectedOffering]);

  return (
    <div data-testid={"order-history-by-sales-code"}>
      <Table
        dataSource={[{key: "testStuff"}]}
        className="rr-table rr-table--alternating-rows"
        pagination={false}
        columns={[
          new TableColumn({
            title: translations["orderHistorySalesCodeLabel"],
            key: "salescode",
            render: () => (
              <Select
                aria-label={"SalesCode"}
                options={salesCodes}
                onChange={(event: any) =>
                  setSelectedSalesCode({code: event.value, name: event.label})
                }
                placeholder={translations["salesCodePlaceholderText"]}
              />
            ),
          }),
          new TableColumn({
            title: translations["orderHistoryOfferingPeriodLabel"],
            key: "offeringPeriod",
            render: () => {
              return (
                <Select
                  aria-label={"Completed Offerings"}
                  options={offerings}
                  onChange={(event: any) =>
                    setSelectedOffering({
                      id: event.value,
                      dateRange: event.label,
                    })
                  }
                  placeholder={translations["offeringPlaceholderText"]}
                  isDisabled={!offeringDropDownButtonEnabled}
                  noOptionsMessage={() => (
                    <TextTranslation textId={"noPastOfferings"} />
                  )}
                />
              );
            },
          }),
          new TableColumn({
            title: translations["orderHistoryOfferingDownloadLabel"],
            key: "download",
            render: () => {
              return (
                <div>
                  <button
                    className={"fmc-button"}
                    onClick={() => downloadButtonHandler()}
                    disabled={!downloadButtonEnabled}>
                    <TextTranslation textId={"orderHistoryDownloadButton"} />
                  </button>
                  <CSVLink
                    data-testid={"csv-download-link"}
                    headers={headers}
                    data={orderHistoryData}
                    filename={orderHistoryFileName}
                    ref={csvDownloadLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                </div>
              );
            },
          }),
        ]}
      />
    </div>
  );
}
