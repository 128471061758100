import React from "react";
import ReactTooltip from "react-tooltip";
import {CSVLink} from "react-csv";
import {TextTranslation} from "../../utils/Translations";

export function SVRDownloadButton(
  downloadCSV: () => void,
  csvFileData: string,
  csvDownloadLink: React.RefObject<any>
) {
  return (
    <div className={"svr-tooltip"}>
      <ReactTooltip
        role={"tooltip"}
        place={"bottom"}
        effect={"solid"}
        border={true}
        borderColor={"black"}
        id={"svr-tooltip"}
        multiline={true}
        offset={{bottom: 8}}
        padding={"1em"}>
        <TextTranslation textId={"downloadCSVTooltip"} />
      </ReactTooltip>
      <span
        data-tip={"data-tip-to-override"}
        data-for={"svr-tooltip"}
        data-tooltip-place={"bottom"}>
        <button
          data-testid={"export-svr-button"}
          className="fmc-button"
          onClick={downloadCSV}>
          <TextTranslation textId={"downloadCSVLabel"} />
        </button>
      </span>
      <CSVLink
        data-testid={"download-link"}
        data={csvFileData}
        filename={"supplementalVehicle.csv"}
        ref={csvDownloadLink}
        rel="noopener noreferrer"
        target="_blank"
      />
    </div>
  );
}
