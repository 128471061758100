import {Country} from "../enums/Country";
import {setGlobalState} from "../state/GlobalState";

export function getCurrentCountry(salesCode: string) {
  let firstLetter = salesCode.at(0);
  let country;
  switch (firstLetter) {
    case "B":
      country = Country.MX;
      break;
    case "C":
      country = Country.CA;
      break;
    default:
      country = Country.US;
      break;
  }
  setGlobalState("country", country);
}
