import {getHttpClient} from "./API";
import {ApiUrls} from "../enums/ApiUrls";

export async function getAvailableVehicleLines(): Promise<string[]> {
  let response = await getHttpClient().get(ApiUrls.availableVehicleLines);
  return response.data;
}

export async function getAvailableVehicleBodyDescriptions(
  salesCode: string,
  vehicleLine: string
): Promise<string[]> {
  let response = await getHttpClient().get(
    ApiUrls.availableVehicleBodyDescriptions + `/${salesCode}/${vehicleLine}`
  );
  return response.data;
}

export async function getCompletedOfferingsForAdmin() {
  const response = await getHttpClient().get(ApiUrls.completedOfferings);
  return response.data;
}

export async function getCompletedOfferingsForDealer(salesCode: string) {
  const response = await getHttpClient().get(
    ApiUrls.completedOfferings + `/${salesCode}`
  );
  return response.data;
}

export async function getCompletedAndActiveOfferings() {
  const response = await getHttpClient().get(
    ApiUrls.completedAndActiveOfferings
  );
  return response.data;
}

export async function getModelEHistoricalRebillingData() {
  return await getHttpClient().get(ApiUrls.getModelERebillingReport);
}

export async function getAvailableVehicleReportData() {
  return await getHttpClient().get(ApiUrls.getModelEAvailableVehicleReport);
}

export type Offering = {
  _id: string;
  startTime: string;
  endTime: string;
  reallocationEndTime: string;
  dateRange: string;
  wholesaleType: string;
};
