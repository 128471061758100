import axios from "axios";
import {getEncodedAccessToken, invalidateLogin} from "./AuthService";
import {getGlobalState, setGlobalState} from "../state/GlobalState";
import {getSkin} from "./SkinService";

export function getHttpClient() {
  axios.interceptors.request.use((config) => {
    const token = getEncodedAccessToken();

    if (config.headers === undefined)
      config.headers = Object.assign({}, config.headers);

    config.headers.Authorization = "Bearer " + token;

    config.headers.wholesaleType = getSkin();
    config.headers.country = getGlobalState("country");

    return config;
  });
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        invalidateLogin();
      } else {
        setGlobalState("errorModalVisible", true);
      }
      return error;
    }
  );
  return axios;
}
