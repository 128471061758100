import Select from "react-select";
import {useEffect, useState} from "react";
import {
  getCompletedAndActiveOfferings,
  Offering,
} from "../../service/OfferingService";
import {AdminPageReports} from "../../components/admin/AdminPageReports";
import {getSkin, Skin} from "../../service/SkinService";
import {DownloadModal} from "../../components/downloadModal/DownloadModal";
import {PermissionsAdminTool} from "../../components/admin/PermissionsAdminTool";
import {getHttpClient} from "../../service/API";
import {AdminStockingGuide} from "../../components/admin/AdminStockingGuide";
import styles from "../../styles/AdminPage.module.scss";
import {AdminGuard} from "../../components/admin/AdminGuard";
import {ModelERebillingReport} from "../../components/admin/ModelERebillingReport";
import {TextTranslation, useTranslation} from "../../utils/Translations";
import {ModelEAvailableVehicleReport} from "../../components/admin/ModelEAvailableVehicleReport";

export function AdminPage() {
  const [offerings, setOfferings] = useState([]);
  const [selectedOffering, setSelectedOffering] = useState({
    id: "",
    dateRange: "",
  });
  const [stillAvailableVehicles, setStillAvailableVehicles] = useState<
    {vin: String; salesCode: String}[]
  >([]);

  const tranlations = useTranslation(["selectOfferingPeriodPlaceholderText"]);

  useEffect(() => {
    getCompletedAndActiveOfferings().then((offeringData) => {
      setOfferings(
        offeringData.map((offerings: Offering) => {
          return {label: offerings.dateRange, value: offerings._id};
        })
      );
    });
  }, []);

  /**
   * Can delete after Rebilling automation is complete
   */
  function getOnHoldVehiclesCall() {
    getHttpClient()
      .get(`/api/modelE/order/onHold`)
      .then((resp) => {
        setStillAvailableVehicles(resp.data.stillAvailable);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <AdminGuard>
      <>
        <h1
          className={
            getSkin() === Skin.LPIVW
              ? styles.lincolnAdminPageHeader
              : styles.adminPageHeader
          }>
          <TextTranslation textId={"adminDashboard"} />
        </h1>
        <div className={styles.adminDashboardContainer}>
          <div className={styles.adminElementContainers}>
            <PermissionsAdminTool />
          </div>
          <div
            className={styles.adminElementContainers}
            style={{gridColumn: "2 / span 3"}}>
            <h2 className={styles.permissionsHeader}>
              <TextTranslation textId={"adminDownloadReports"} />
            </h2>
            <p className={styles.adminText}>
              <TextTranslation textId={"adminReportDescription"} />
            </p>

            <table className={styles.adminBar}>
              <thead>
                <tr className={styles.adminBarTop}>
                  <th>
                    <TextTranslation textId={"adminOfferPeriod"} />{" "}
                  </th>
                  <th>
                    <TextTranslation textId={"adminDownloadReportRow"} />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Select
                      aria-label={"Offerings"}
                      className={styles.select}
                      placeholder={
                        tranlations["selectOfferingPeriodPlaceholderText"]
                      }
                      options={offerings}
                      onChange={(event: any) =>
                        setSelectedOffering({
                          id: event.value,
                          dateRange: event.label,
                        })
                      }
                    />
                  </td>
                  <td>
                    <AdminPageReports offeringId={selectedOffering.id} />
                  </td>
                </tr>
              </tbody>
            </table>
            <DownloadModal />
          </div>
          {/*Can delete after Rebilling automation is complete*/}
          {getSkin() === Skin.MEW && (
            <>
              <div className={styles.adminElementContainers}>
                <button
                  data-testid="on-hold-button"
                  className="fmc-button"
                  onClick={() => getOnHoldVehiclesCall()}>
                  Get ON HOLD vehicles
                </button>
                <ul className={styles.onHoldVehicles}>
                  {stillAvailableVehicles &&
                    stillAvailableVehicles.map((vehicle) => (
                      <li key={vehicle.vin.toString()}>
                        VIN: {vehicle["vin"]} - Sales Code:{" "}
                        {vehicle["salesCode"]}
                      </li>
                    ))}
                </ul>
              </div>
              <div className={styles.adminElementContainers}>
                <h2 className={styles.permissionsHeader}>
                  Update Stock Target
                </h2>
                <AdminStockingGuide />
              </div>
              <div className={styles.rebillingReportContainer}>
                <h2 className={styles.reportHeader}>
                  Rebilled Vehicle History Report
                </h2>
                <ModelERebillingReport />
              </div>
              <div className={styles.rebillingReportContainer}>
                <h2 className={styles.reportHeader}>
                  Available Vehicle Report
                </h2>
                <ModelEAvailableVehicleReport />
              </div>
            </>
          )}
        </div>
      </>
    </AdminGuard>
  );
}
