import {getModelEHistoricalRebillingData} from "../../service/OfferingService";
import React, {LegacyRef, useRef, useState} from "react";
import {AxiosResponse} from "axios";
import {setGlobalState} from "../../state/GlobalState";
import {CSVLink} from "react-csv";
import styles from "../../styles/ModelERebillingReport.module.scss";

export function ModelERebillingReport() {
  const [reportData, setReportData] = useState([]);
  const [reportFilename, setReportFilename] = useState("");

  const csvRebillingDownloadLink: LegacyRef<any> = useRef();

  const createFileForDownload = (response: AxiosResponse<any>) => {
    setReportData(response.data);
    let fileName = `Model E Historical Rebilling Report Until ${new Date()}`;
    setReportFilename(fileName);
  };

  const getModelEReport = async () => {
    await getModelEHistoricalRebillingData()
      .then((response) => {
        createFileForDownload(response);
      })
      .finally(() => setGlobalState("downloadModalVisible", true));
    if (csvRebillingDownloadLink.current)
      csvRebillingDownloadLink.current.link.click();
  };

  return (
    <div className={styles.reportButton}>
      <button
        data-testid="model-e-report-button"
        className="fmc-button"
        onClick={() => getModelEReport()}>
        Model-E Rebilling Report
      </button>
      <CSVLink
        data-testid={"csv-download-report"}
        data={reportData}
        filename={reportFilename}
        ref={csvRebillingDownloadLink}
        rel="noopener noreferrer"
        target="_blank"
      />
    </div>
  );
}
