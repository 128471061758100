import {createGlobalState} from "react-hooks-global-state";
import {DealerSelection} from "../models/DealerSelection";
import {
  FormattedOrder,
  FormattedVehicleLine,
  VehicleLineWersDescriptions,
} from "../types/order";
import {DropdownOption, RangeOption} from "../types/filter-option";
import {Language} from "../enums/Language";
import {getSkin, Skin} from "../service/SkinService";

type GlobalStateProps = {
  salesCode: string;
  dealerSelectionList: DealerSelection[];
  orderGridData: FormattedOrder[];
  orderGridDataWithVehicleLines: FormattedVehicleLine[];
  errorModalVisible: boolean;
  submissionMade: boolean;
  offeringWindowOngoing: boolean;
  reallocationPeriodOngoing: boolean;
  hasReadAllPermissions: boolean;
  hasAdminPermissions: boolean;
  email: string | null;
  vehicleLine: string;
  filterDropDownOptions: Map<string, DropdownOption>;
  filterRangeOptions: Map<string, RangeOption>;
  svrModalVisible: boolean;
  downloadModalVisible: boolean;
  language: Language;
  wersDescriptions: VehicleLineWersDescriptions;
  country: string;
  featureFlags: string[];
};

const initialState: GlobalStateProps = {
  salesCode: "",
  dealerSelectionList: [],
  orderGridData: [],
  orderGridDataWithVehicleLines: [],
  errorModalVisible: false,
  submissionMade: false,
  offeringWindowOngoing: false,
  reallocationPeriodOngoing: false,
  hasReadAllPermissions: false,
  hasAdminPermissions: false,
  email: null,
  vehicleLine: "",
  filterDropDownOptions: new Map<string, DropdownOption>(),
  filterRangeOptions: new Map<string, RangeOption>(),
  svrModalVisible: false,
  downloadModalVisible: false,
  language: getLanguageInLocalStorage(),
  wersDescriptions: {} as VehicleLineWersDescriptions,
  country: "",
  featureFlags: [],
};

export function getLanguageInLocalStorage(): Language {
  const language =
    (localStorage.getItem("language") as Language) ??
    (navigator.language.slice(0, 2) as Language);
  const skin = getSkin();
  switch (skin) {
    case Skin.MAP:
      if (language === Language.FR) return Language.FR;
      break;
    case Skin.CVW:
      if (language === Language.ES) return Language.ES;
      break;
    case Skin.LPIVW:
      if (language === Language.FR) return Language.FR;
      break;
  }
  return Language.EN;
}

export const {useGlobalState, setGlobalState, getGlobalState} =
  createGlobalState(initialState);

export const resetGlobalState = () => {
  setGlobalState("salesCode", initialState.salesCode);
  setGlobalState("dealerSelectionList", initialState.dealerSelectionList);
  setGlobalState("orderGridData", initialState.orderGridData);
  setGlobalState("errorModalVisible", initialState.errorModalVisible);
  setGlobalState("submissionMade", initialState.submissionMade);
  setGlobalState("offeringWindowOngoing", initialState.offeringWindowOngoing);
  setGlobalState(
    "reallocationPeriodOngoing",
    initialState.reallocationPeriodOngoing
  );
  setGlobalState("hasReadAllPermissions", initialState.hasReadAllPermissions);
  setGlobalState("email", initialState.email);
  setGlobalState("vehicleLine", initialState.vehicleLine);
  setGlobalState("svrModalVisible", initialState.svrModalVisible);
  setGlobalState("filterDropDownOptions", initialState.filterDropDownOptions);
  setGlobalState("filterRangeOptions", initialState.filterRangeOptions);
  setGlobalState("downloadModalVisible", initialState.downloadModalVisible);
  setGlobalState("language", initialState.language);
  setGlobalState("wersDescriptions", initialState.wersDescriptions);
  setGlobalState("country", initialState.country);
  setGlobalState("featureFlags", initialState.featureFlags);
};
