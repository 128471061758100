import {setGlobalState, useGlobalState} from "../../state/GlobalState";
import {LegacyRef, useRef, useState} from "react";
import {CSVLink} from "react-csv";
import {getRebillingReportData} from "../../service/ReportService";
import {TextTranslation} from "../../utils/Translations";

export const ExportRebillingReport = () => {
  const [hasAdminPermissions] = useGlobalState("hasAdminPermissions");
  const [csvData, setCsvData] = useState<string>("");
  const [fileName, setFileName] = useState("");
  const csvDownloadLink: LegacyRef<any> = useRef();

  const getRebillingReport = () => {
    getRebillingReportData()
      .then((response) => {
        setCsvData(response.data);
        setFileName(
          response.headers["content-disposition"]
            .split("=")[1]
            .replaceAll('"', "")
        );
        if (csvDownloadLink.current) csvDownloadLink.current.link.click();
      })
      .finally(() => setGlobalState("downloadModalVisible", true));
  };

  return (
    <>
      {hasAdminPermissions && (
        <div>
          <button
            className={"fmc-button admin-tool-button"}
            onClick={getRebillingReport}
            data-testid={"export-rebilling-report"}>
            <TextTranslation textId={"exportRebillingReportButton"} />
          </button>
          <CSVLink
            data-testid={"rebilling-download-link"}
            data={csvData}
            filename={fileName}
            ref={csvDownloadLink}
            rel="noopener noreferrer"
            target="_blank"
          />
        </div>
      )}
    </>
  );
};
