import {TextTranslation} from "../../utils/Translations";

export function CvwFAQ() {
  return (
    <div className={"content-width"}>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"faqFordAcceptPortal"}></TextTranslation>
        </span>
      </p>
      <p className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"faqFordFIIAuthority"}></TextTranslation>
        </span>
      </p>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation
            textId={"faqFordNoVehicleOfferings"}></TextTranslation>
        </span>
      </p>
      <p className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"faqFordDealershipEarned"}></TextTranslation>
        </span>
      </p>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation
            textId={"faqFordAcceptedBeRebilled"}></TextTranslation>
        </span>
      </p>
      <p className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation
            textId={"faqFordAcceptedBeRebilledA"}></TextTranslation>
        </span>
      </p>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"faqFordAmendOrders"}></TextTranslation>
        </span>
      </p>
      <p className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"faqFordAmendOrdersA"}></TextTranslation>
        </span>
      </p>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"faqFordITTroubles"}></TextTranslation>
        </span>
      </p>
      <p className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"faqFordITTroublesA"}></TextTranslation>
        </span>
      </p>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"faqFordFindAccepted"}></TextTranslation>
        </span>
      </p>
      <p className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"faqFordFindAcceptedA"}></TextTranslation>
        </span>
      </p>
    </div>
  );
}
