import {OrderFeature, VehicleLineWersDescriptions} from "../types/order";
import {getAllVehicleLineDescriptions} from "./OrderService";
import {setGlobalState} from "../state/GlobalState";
import {ConfigurationFeature} from "../models/Configuration";

export function formatAsCurrency(currency: number): string {
  return currency.toLocaleString(new Intl.Locale("en-US"), {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
}

export function getAllDescriptionsByVehicleLineAndCountry(
  vehicleLine: string,
  currentCountry: string
) {
  getAllVehicleLineDescriptions(vehicleLine, currentCountry).then(
    (response: {data: VehicleLineWersDescriptions}) => {
      if (response.data && Object.keys(response.data).length !== 0) {
        const responseData = response.data;
        const descriptionsMap = new Map<string, Map<string, string>>();
        if (responseData.descriptions) {
          Object.entries(responseData.descriptions).forEach(([key, value]) => {
            descriptionsMap.set(key, new Map(Object.entries(value as any)));
          });
        }
        setGlobalState("wersDescriptions", {
          ...responseData,
          descriptions: descriptionsMap,
        });
      }
    }
  );
}

export function searchDescription(
  locale: string,
  code: OrderFeature,
  wersDescriptions: VehicleLineWersDescriptions
) {
  if (
    wersDescriptions &&
    wersDescriptions.descriptions &&
    wersDescriptions.descriptions.get(locale.toString()) &&
    wersDescriptions.descriptions.get(locale.toString())?.has(code.wersCode)
  ) {
    return wersDescriptions.descriptions.get(locale)?.get(code.wersCode);
  }

  return code.description;
}

export function searchDescriptionModelE(
  locale: string,
  code: ConfigurationFeature,
  wersDescriptions: VehicleLineWersDescriptions
) {
  if (
    wersDescriptions &&
    wersDescriptions.descriptions &&
    wersDescriptions.descriptions.get(locale.toString()) &&
    wersDescriptions.descriptions.get(locale.toString())?.has(code.wersCode)
  ) {
    return wersDescriptions.descriptions
      .get(locale)
      ?.get(code.wersCode.toString());
  }

  return code.description;
}
