export enum Skin {
  CVW = "cvw",
  MEW = "mew",
  MAP = "map",
  LPIVW = "lpivw",
  IPW = "ipw",
}

export function getSkin(): Skin {
  if (hostnameContainsLincoln()) {
    return Skin.LPIVW;
  }

  switch (startOfHostname()) {
    case "ipw":
      return Skin.IPW;

    case "evdealerstockingportal":
      return Skin.MEW;

    case "configuredvehiclewholesale":
      return Skin.CVW;

    default:
      return Skin.MAP;
  }

  function hostnameContainsLincoln() {
    return window.location.hostname.includes("lincoln");
  }

  function startOfHostname() {
    const regexForDotOrDash = /[.-]/;
    return window.location.hostname.split(regexForDotOrDash)[0];
  }
}
