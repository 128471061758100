import {setEncodedAccessToken} from "./AuthService";
import {SessionStorageKeys} from "../enums/SessionStorageKeys";

function parseAndStoreAccessTokenVariables(urlSearchParams: URLSearchParams) {
  const accessToken = urlSearchParams.get("access_token") as string;
  const accessTokenJson = JSON.parse(atob(accessToken.split(".")[1]));
  setEncodedAccessToken(accessToken);
  sessionStorage.setItem(
    SessionStorageKeys.CDSID,
    accessTokenJson["CommonName"]
  );
  if (accessTokenJson["emailaddress"]) {
    sessionStorage.setItem(
      SessionStorageKeys.EMAIL,
      accessTokenJson["emailaddress"]
    );
  }
  sessionStorage.setItem(
    SessionStorageKeys.FIRST_NAME,
    accessTokenJson["givenname"] ? accessTokenJson["givenname"] : "Dealer"
  );
}

export function parseOAuthCallbackUrl(url: string) {
  const urlParameters = url.split("#")[1];
  const urlSearchParams = new URLSearchParams(urlParameters);
  parseAndStoreAccessTokenVariables(urlSearchParams);
}
